import axios from 'axios'
import {  Message } from 'element-ui'
// 创建一个axios实例
const service = axios.create({
  // 由于这里时我自己做了跨域代理，所以去掉了
  // baseURL: 'https://dropshipping.vhost.lanyun2009.com'
  baseURL: 'https://www.stdropshipping.com'
})
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data

    // 如果自定义代码不是200，则判断为错误
    if (res.code !== 200) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    
    return Promise.reject(error)
  }
)

export default service
